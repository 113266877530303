
import { metaDataMixin } from '~/mixins'

export default {
  mixins: [
    metaDataMixin,
  ],
  async asyncData({ $api, $moment, $config }) {
    // Get all available events
    let [
      { data: { items: events = [] } = {} },
    ] = await Promise.all([
      $api.events.getAll({ limit: 99 }),
    ])

    // Get more data on each event
    events = await Promise.all(events.map(async (event) => {
      const { data: { items = [] } = {} } = await $api.events.getOneBySlug(event.slug)
      return {
        ...event,
        ...items[0],
        environmentSlug: $config.prepr.environments[event.environmentId]?.slug,
        environmentUrl: $config.prepr.environments[event.environmentId]?.baseUrl,
      }
    }))

    // Only current or upcoming events
    events = events.filter(event => {
      $moment.relativeTimeThreshold("ss", 0)
      const now = $moment()
      const start = isNaN(event.startDate) ? $moment(event.startDate) : $moment.unix(event.startDate)
      const minutesFromStart = now.diff(start, "minutes", true)
      if (event.endDate) {
        const end = isNaN(event.endDate) ? $moment(event.endDate) : $moment.unix(event.endDate)
        const minutesFromEnd = now.diff(end, "minutes", true)
        return minutesFromEnd <= 0
      } else {
        return minutesFromStart < 0
      }
    })

    // Sort by closest start date
    events = events.sort((eventA, eventB) => {
      return Date.parse(eventA.startDate) <= Date.parse(eventB.startDate) ? -1 : 1
    })

    return {
      events,
    }
  },
  data() {
    return {
      calltoactions: [],
      events: [],
    }
  },
}
